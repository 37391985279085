.clientsucess button.slick-arrow {
  width: 40px;
  height: 40px;
  background-color: #ffffff;
  border-radius: 100% !important;
}
.clientsucess button.slick-arrow.slick-prev {
  right: 100px;
  left: auto;
  top: auto;
  bottom: -15px;
  background-image: url(https://res.cloudinary.com/dthpnue1d/image/upload/v1716468949/Vector_left_6840167414.png?updated_at=2024-05-23T12:55:50.290Z);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px;
}
.clientsucess button.slick-arrow.slick-next {
  right: 50px;
  bottom: -15px;
  top: auto;
  background-image: url(https://res.cloudinary.com/dthpnue1d/image/upload/v1716468950/Vector_right_bfcdb19ef0.png?updated_at=2024-05-23T12:55:50.392Z);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px;
}
.clientsucess .slick-slider.slick-initialized {
  padding: 0px 0px 50px 0px;
}
.clientsucess .slick-prev:before,
.clientsucess .slick-next:before {
  opacity: 0;
}
.clientsucess .slick-slide.slick-active.slick-current {
  padding-left: 0px;
}

.clientsucess .slick-slide.slick-cloned {
  padding-right: 0px;
}

.ourClient p span {
  font-size: 40px;
}
.ourClient p span strong {
  font-size: 55px;
}

@media only screen and (max-width: 720px) {
  .ourClient p span {
    font-size: 16px;
    font-weight: 600;
  }
  .ourClient p span strong {
    font-size: 22px;
    font-weight: 700;
  }
}
